import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-progress-job',
  templateUrl: './progress-job.component.html',
  styleUrls: ['./progress-job.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProgressJobComponent implements OnInit {
  @Input() jobDescription: string;
  @Input() currentProgress: number;
  @Input() sideNote: string;

  @Output() closed$: EventEmitter<void> = new EventEmitter<void>();
  constructor() { }

  ngOnInit(): void {
  }

  handleClose(): void {
    this.closed$.next();
  }

}

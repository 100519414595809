import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppProgressComponent } from './app-progress.component';
import {SharedModule} from "../shared/shared.module";
import { ProgressJobComponent } from './progress-job/progress-job.component';



@NgModule({
  declarations: [AppProgressComponent, ProgressJobComponent],
  imports: [
    CommonModule,
    SharedModule.forChild()
  ],
  exports: [AppProgressComponent]
})
export class AppProgressModule { }

<div class="menu-bar" fxLayout="row" fxLayoutAlign="center center">
  <ng-container *ngFor="let model of menubarModel">
    <ng-container *ngIf="!model.subMenu">
      <ng-container *ngTemplateOutlet="menuButtonTmpl;
      context: {icon: model.icon, label: model.label, id: model.id, route: model.route}"></ng-container>
    </ng-container>

    <ng-container *ngIf="model.subMenu">
      <ng-container *ngTemplateOutlet="subMenuButtonTmpl;
      context: {icon: model.icon, label: model.label, id: model.id, subMenu: model.subMenu}"></ng-container>
    </ng-container>
  </ng-container>
</div>

<ng-template #menuButtonTmpl let-icon="icon" let-label="label" let-id="id" let-route="route">
    <button mat-button class="menu-button" id="{{id}}" (click)="handleButtonClick(id, route)" [ngClass]="{'active': activeMenuId === id}" [routerLink]="route">
    <span fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px">
      <mat-icon [inline]="true">{{icon}}</mat-icon>
      <span class="menu-label">{{label}}</span>
    </span>
    </button>
</ng-template>

<ng-template #subMenuButtonTmpl let-icon="icon" let-label="label" let-id="id" let-subMenu="subMenu">
  <button mat-button [matMenuTriggerFor]="menu" class="menu-button" [ngClass]="{'active': id === _currParentMenu}">
    <span fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px">
      <mat-icon [inline]="true">{{icon}}</mat-icon>
      <span class=" menu-label">{{label}}</span>
    </span>
  </button>
  <mat-menu #menu="matMenu">
<!--   TODO: add router link -->
    <button mat-menu-item *ngFor="let config of subMenu" id="{{config.id}}" (click)="handleButtonClick(config.id, config.route)" [routerLink]="config.route">
      <mat-icon color="accent" *ngIf="activeMenuId === config.id">{{config.icon}}</mat-icon>
      <mat-icon *ngIf="activeMenuId !== config.id">{{config.icon}}</mat-icon>
      <span [ngClass]="{'active': activeMenuId === config.id}">{{config.label}}</span>
    </button>
  </mat-menu>
</ng-template>


// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const backendUrl = 'localhost:8080';

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

export const environment = {
  production: false,
  appName: 'EURYKA',
  version: '0.1',
  envName: 'LOCAL',
  envID: 'local',
  backendUrl: backendUrl,
  AUTH_BASE64: "ZXVyeWthOmV1cnlrYTEyMw==",
  FEATURES_GET_EP: '/api/features/get',
  clientName: "DEV"
};

import {BrowserModule, Title} from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {SharedModule} from "./shared/shared.module";
import {NavBarModule} from "./components/non-modular-components/nav-bar/nav-bar.module";
import {FlexLayoutModule} from "@angular/flex-layout";
import {MenuBarModule} from "./components/modular-components/menu-bar/menu-bar.module";
import {HttpClientModule} from "@angular/common/http";
import {ResultSnackBarComponent} from "./components/modular-components/result-snack-bar/result-snack-bar.component";

/* Add Amplify imports */
import { AmplifyUIAngularModule } from '@aws-amplify/ui-angular';
import Amplify, { Auth } from 'aws-amplify';
import {NgxSpinnerModule} from "ngx-spinner";
import {AppProgressModule} from "./app-progress/app-progress.module";

/* Configure Amplify resources */
Amplify.configure(Auth.configure());

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    AmplifyUIAngularModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    SharedModule.forRoot(),
    NavBarModule,
    MenuBarModule,
    NgxSpinnerModule,
    AppProgressModule
  ],
  providers: [Title],
  bootstrap: [AppComponent],
})
export class AppModule { }

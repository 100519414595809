import {Injectable} from "@angular/core";
import {AppAuthService} from "../../../shared/services/app-auth.service";
import {Subject} from "rxjs";

@Injectable()
export class NavBarService {
  private readonly _openLoginPopup$: Subject<void> = new Subject<void>()
  get openLoginPopup$() {
    return this._openLoginPopup$
  }
  constructor(private appLoginService:AppAuthService) {
  }

  get user$() {
    return this.appLoginService.user$;
  }

  get authState$() {
    return this.appLoginService.authState$;
  }

  logout(): any{
    this.appLoginService.logout();
  }

  openLoginPopup(): void {
    this._openLoginPopup$.next();
  }
}

import {MenuBarModel} from "./components/modular-components/menu-bar/menu-bar.model";

export const menubarConfig: MenuBarModel[] = [
  {icon: 'home', label: 'Home', id: 'home', route: ['/app/home']},
  {icon: 'source', label: 'Source', id: 'source', route: ['/app/journey/entity/source']},
  {icon: 'menu_book', label: 'Glossary', id: 'domainGroup', route: ['/app/journey/entity/domainGroup']},
  {icon: 'view_headline', label: 'Common Data Model', id: 'reference-data', route: ['/app/journey/entity/tableName']},
  {icon: 'people', label: 'Consumer', id: 'consumer', route: ['/app/journey/entity/consumer']},
  {icon: 'data_usage', label: 'Data Quality Rule', id: 'dataQualityRule', route: ['/app/journey/entity/dataQualityRule']},
  // {icon: 'accessibility', label: 'Admin', id: 'admin', route: ''},
  {icon: 'view_list', label: 'Type', id: 'types', subMenu:
      [
        {
          icon: 'list',
          label: 'System',
          id: 'system',
          route: ['/app/journey/entity/system']
        },
        {
          icon: 'list',
          label: 'Data Type',
          id: 'dataType',
          route: ['/app/journey/entity/dataType']
        },
        {
          icon: 'list',
          label: 'Feed Type',
          id: 'feedType',
          route: ['/app/journey/entity/feedType']
        },
        {
          icon: 'list',
          label: 'Channel',
          id: 'channel',
          route: ['/app/journey/entity/channel']
        },
        {
          icon: 'list',
          label: 'Frequency Type',
          id: 'frequencyType',
          route: ['/app/journey/entity/frequencyType']
        },
        {
          icon: 'list',
          label: 'Scale',
          id: 'scale',
          route: ['/app/journey/entity/scale']
        },
        {
          icon: 'list',
          label: 'Glossary Release Version Type',
          id: 'glossaryReleaseVersionType',
          route: ['/app/journey/entity/glossaryReleaseVersionType']
        },
        {
          icon: 'list',
          label: 'Data Model Type',
          id: 'dataModelType',
          route: ['/app/journey/entity/dataModelType']
        },
        {
          icon: 'list',
          label: 'Mapping Version Type',
          id: 'mappingVersionType',
          route: ['/app/journey/entity/mappingVersionType']
        },
        {
          icon: 'list',
          label: 'Data Model Version Type',
          id: 'dataModelVersionType',
          route: ['/app/journey/entity/dataModelVersionType']
        },
        {
          icon: 'list',
          label: 'Consumer Type',
          id: 'consumerType',
          route: ['/app/journey/entity/consumerType']
        },
        {
          icon: 'list',
          label: 'Data Quality type',
          id: 'dataQualityType',
          route: ['/app/journey/entity/dataQualityType']
        },
        {
          icon: 'list',
          label: 'PII Classification',
          id: 'piiClassification',
          route: ['/app/journey/entity/piiClassification']
        }
      ]
  },
  {icon: 'share', label: 'Mapping', id: 'mappings', subMenu:
      [
        {
          icon: 'list',
          label: 'Source Feed Field to Table Column',
          id: 'sourceFeedFieldTotableColumn',
          route: ['/app/mapping/sourceFeedField/tableColumn']
        },
        {
          icon: 'list',
          label: 'Source Feed Field to Glossary',
          id: 'sourceFeedFieldToGlossary',
          route: ['/app/mapping/sourceFeedField/glossary']
        },
        {
          icon: 'list',
          label: 'Consumer Field to Glossary',
          id: 'consumerFieldToGlossary',
          route: ['/app/mapping/consumerField/glossary']
        },
        {
          icon: 'list',
          label: 'Consumer Field to Table Column',
          id: 'consumerFieldTotableColumn',
          route: ['/app/mapping/consumerField/tableColumn']
        },
        {
          icon: 'list',
          label: 'Table Column to Glossary',
          id: 'tableColumnToglossary',
          route: ['/app/mapping/tableColumn/glossary']
        },
        {
          icon: 'list',
          label: 'Data Quality Rule to Glossary',
          id: 'dataQualityRuleToglossary',
          route: ['/app/mapping/dataQualityRule/glossary']
        },
        {
          icon: 'list',
          label: 'Table Column to Table Column',
          id: 'tableColumnTotableColumn',
          route: ['/app/mapping/fromTableColumn/toTableColumn']
        },
      ],

  },
  // {icon: 'waves', label: 'Data Flow', id:'dataflow', route: '/app/journey/dataflow'},
  {icon: 'waves', label: 'Data Flow', id:'dataflow',
    subMenu: [
      {
        icon: 'list',
        label: 'Source',
        id: 'dataFlowSource',
        route: ['/app/journey/dataflow/source']
      },
      {
        icon: 'list',
        label: 'Source Product',
        id: 'dataFlowSourceProduct',
        route: ['/app/journey/dataflow/sourceProduct']
      },
      {
        icon: 'list',
        label: 'Source Feed',
        id: 'dataFlowSourceFeed',
        route: ['/app/journey/dataflow/sourceFeed']
      },
      {
        icon: 'list',
        label: 'Source Feed Field',
        id: 'dataFlowSourceFeedField',
        route: ['/app/journey/dataflow/sourceFeedField']
      },
      {
        icon: 'list',
        label: 'Domain Group',
        id: 'dataFlowDomainGroup',
        route: ['/app/journey/dataflow/domainGroup']
      },
      {
        icon: 'list',
        label: 'Glossary',
        id: 'dataFlowGlossary',
        route: ['/app/journey/dataflow/glossary']
      },
      {
        icon: 'list',
        label: 'Table Name',
        id: 'dataFlowTableName',
        route: ['/app/journey/dataflow/tableName']
      },
      {
        icon: 'list',
        label: 'Table Column',
        id: 'dataFlowTableColumn',
        route: ['/app/journey/dataflow/tableColumn']
      },
      {
        icon: 'list',
        label: 'Consumer',
        id: 'dataFlowConsumer',
        route: ['/app/journey/dataflow/consumer']
      },
      {
        icon: 'list',
        label: 'Consumer Feed',
        id: 'dataFlowConsumerFeed',
        route: ['/app/journey/dataflow/consumerFeed']
      },
      {
        icon: 'list',
        label: 'Consumer Field',
        id: 'dataFlowConsumerField',
        route: ['/app/journey/dataflow/consumerField']
      }
    ]
  },
  // {icon: 'favorite', label: 'My Favourites', id: 'favourite', route: ''},

]

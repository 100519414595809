import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {AppProgressService, ProgressModel} from "../shared/services/app-progress.service";
import {Observable} from "rxjs";

@Component({
  selector: 'app-progress',
  templateUrl: './app-progress.component.html',
  styleUrls: ['./app-progress.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppProgressComponent implements OnInit {
  progress$: Observable<ProgressModel[]>;
  constructor(private appProgressService:AppProgressService) { }

  ngOnInit(): void {
    this.progress$ = this.appProgressService.progress$;
  }

  deleteJob(id: string): void {
    this.appProgressService.deleteJob(id);
  }

}

import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {NavBarService} from "./nav-bar.service";
import {filter, take, tap} from "rxjs/operators";
import {AuthState} from "@aws-amplify/ui-components";
import {pipe} from "rxjs";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {EurykaUserInterface} from "../../../model/euryka-user.model";

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit {
  hide = true;
  loading: boolean;
  @ViewChild('authTmpl') authTmpl: TemplateRef<any>;
  hideSearchbar: boolean;
  user: EurykaUserInterface;

  get authState$() {
    return this.navbarService.authState$;
  }

  constructor(private router: Router, private dialog: MatDialog, private navbarService: NavBarService) { }

  ngOnInit(): void {
    this.navbarService.openLoginPopup$.subscribe(()=>{
      this.handleLogin();
    })
    this.router.events.pipe(
      filter((event)=> event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd)=> {
      this.hideSearchbar = (event.url === "/app/home")
    })
    this.navbarService.user$.subscribe((user)=>{
      this.user = user;
    })
  }

  handleLogin(): void {
    const dialogRef = this.dialog.open(this.authTmpl, {
      minHeight: 'auto'
    });
    this.authState$.pipe(filter((state=>state === AuthState.SignedIn)), take(1))
      .subscribe(()=>{
        dialogRef.close()
      })
  }

  handleLogout(): void {
    this.navbarService.logout();
  }

}

import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {v4 as uuid} from 'uuid';
import {environment} from "../../../../environments/environment";
import {AppAuthService} from "../app-auth.service";
import {EurykaUserInterface} from "../../../model/euryka-user.model";

const blobServicesList: string[] = [];
function isBlobRequest(requestUrl: string):boolean {
  let _isBlobRequest: boolean = false;
  blobServicesList.forEach((serviceName: string)=>{
    if(requestUrl.includes(serviceName)) {
      _isBlobRequest = true;
    }
  });
  return _isBlobRequest;
}

@Injectable()
export class AppHttpRequestInterceptor implements HttpInterceptor {

  constructor(private appAuthService:AppAuthService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const correlationId: string = uuid();
    let jwtToken: string;
    const user: EurykaUserInterface = this.appAuthService.user$.getValue();
    if (user && user['signInUserSession']) {
      jwtToken = user['signInUserSession']['accessToken']['jwtToken'];
    }
    let newRequest: HttpRequest<any> = req.clone({
      setHeaders: {
        'Authorization': `Basic ${environment.AUTH_BASE64}`,
        // 'Authorization': `Bearer ${jwtToken}`,
        'Accept': '*/*',
        'content-type': 'application/json',
        'x-correlation-id': correlationId,
        // 'updatedBy': (this.appAuthService && this.appAuthService.user$.getValue()) ? this.appAuthService.user$.getValue()['attributes']['name'] : null
      },
      // responseType: this.getResponseType(request.url),
    });
    // if (this.appAuthService.user$.getValue() && this.appAuthService.user$.getValue().username) {
    //   newRequest = newRequest.clone({
    //     setHeaders: {
    //       'updatedBy': this.appAuthService.user$.getValue().username
    //     }
    //   })
    // }
    return next.handle(newRequest);
  }
  /**
   * Get response type based on the service type
   * @param {string} requestUrl
   * @returns {"arraybuffer" | "blob" | "json" | "text"}
   */
  getResponseType(requestUrl: string) : 'arraybuffer' | 'blob' | 'json' | 'text' {
    let _responseType: 'arraybuffer' | 'blob' | 'json' | 'text' = 'json';
    if(isBlobRequest(requestUrl)) {
      _responseType = 'blob';
    }
    // else if(environment.COMPRESS_TRANSMISSION===true) {
    //   _responseType = 'text';
    // }
    return _responseType;
  }

}

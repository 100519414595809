import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuBarComponent } from './menu-bar.component';
import {SharedModule} from "../../../shared/shared.module";
import {RouterModule} from "@angular/router";



@NgModule({
  declarations: [MenuBarComponent],
  exports: [
    MenuBarComponent
  ],
  imports: [
    CommonModule,
    SharedModule.forChild(),
    RouterModule
  ]
})
export class MenuBarModule { }

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
  { path: 'app', loadChildren: () => import('app/components/lazy/app-lazy.module').then(m => m.AppLazyModule)}, // protected lazy loading components
  { path: '', redirectTo: '/app/home', pathMatch: 'full' },
  { path: '**', redirectTo: 'error', pathMatch: 'full' } // any unrecognized urls
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {enableTracing: false, onSameUrlNavigation: "reload"})],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import {Injectable, NgZone} from "@angular/core";
import {AuthState, onAuthUIStateChange} from "@aws-amplify/ui-components";
import {Auth} from 'aws-amplify';
import {BehaviorSubject} from "rxjs";
import {Router} from "@angular/router";
import {EurykaUserInterface, EurykaUserRole} from "../../model/euryka-user.model";

@Injectable({providedIn: "root"})
export class AppAuthService {
  private readonly _user$: BehaviorSubject<EurykaUserInterface | undefined> = new BehaviorSubject<EurykaUserInterface | undefined>(null);
  get user$(): BehaviorSubject<EurykaUserInterface | undefined> {
    return this._user$
  }

  private readonly _authState$: BehaviorSubject<AuthState> = new BehaviorSubject<AuthState>(null);
  get authState$(): BehaviorSubject<AuthState> {
    return this._authState$;
  }

  get isReadonlyUser(): boolean {
    const user = this.user$.getValue();
    return user && user['roles'] &&
      (user['roles'].length === 0 ||
      user['roles'].findIndex((role) => role.value === EurykaUserRole.readOnlyUser) >= 0);

    // return this._user$.getValue() && this._user$.getValue()['attributes']['custom:admin'] === "true"
  }

  get username(): string {
    return this._user$.getValue().username;
  }

  constructor(private ngZone: NgZone, private router: Router) {
    Auth.currentAuthenticatedUser().then((user: EurykaUserInterface)=>{
      this.ngZone.run(()=>{
        this.updateUser(user);
        this._authState$.next(AuthState.SignedIn);
        // this._role = user && user['signInUserSession']['idToken']['payload']['cognito:groups']
      })
    })
    onAuthUIStateChange((authState, authData) => {
      console.log("#####################user: ",authData)
      // onAuthUIStateChange runs outside of NgZone... the callback functions will also run outside of NgZone, change detection will not happen.
      this.ngZone.run(() => {
        this._authState$.next(authState);
        this.updateUser(authData as EurykaUserInterface);
      })
    })
  }

  async logout(): Promise<any> {
    Auth.signOut().then(() => {
      this.ngZone.run(() => {
        this.updateUser(null);
        this._authState$.next(AuthState.SignedOut);
        // this.router.navigate(['/app/home'])
      })
    })
  }

  setUserRole(user: EurykaUserInterface): void {
    if (user && user['signInUserSession']) {
      if (user['signInUserSession']['idToken']['payload']['cognito:groups'] && user['signInUserSession']['idToken']['payload']['cognito:groups'].length > 0) {
        user['roles'] = user['signInUserSession']['idToken']['payload']['cognito:groups'].map((role)=>{
          return {
            label: this.convertToRoleLabel(role),
            value: role
          }
        })
      } else {
        if (user) {
          user['roles'] = [{
            label: 'Read Only User',
            value: EurykaUserRole.readOnlyUser
          }]
        };
      }
    } else {
      user && (user['roles'] = null);
    }
  }

  updateUser(user: EurykaUserInterface): void {
    this.setUserRole(user);
    this._user$.next(user);
  }

  convertToRoleLabel(role: EurykaUserRole): string {
    switch(role) {
      case EurykaUserRole.sourceAdmin:
        return "Source Admin"
      case EurykaUserRole.glossaryAdmin:
        return "Glossary Admin"
      case EurykaUserRole.consumerAdmin:
        return "Consumer Admin"
      case EurykaUserRole.dataQualityRuleAdmin:
        return "Data Quality Admin"
      case EurykaUserRole.superAdmin:
        return 'Super Admin'
      case EurykaUserRole.tableColumnAdmin:
        return 'Common Data Model Admin'
    }
  }
}

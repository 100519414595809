import {Component, OnInit} from '@angular/core';
import {OverlayContainer} from "@angular/cdk/overlay";
import {ThemePickerService} from "./components/modular-components/theme-picker/theme-picker.service";
import {menubarConfig} from "./menu-bar-config";
import {NavigationEnd, NavigationStart, Router, RouterEvent} from "@angular/router";
import {filter} from "rxjs/operators";
import {AppNavigationService} from "./shared/services/app.navigation.service";
import {Title} from "@angular/platform-browser";
import {environment} from "../environments/environment";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'euryka-ng';
  hide = true;

  // use this to set correct theme class on app holder
  // eg: <div [class]="themeClass">...</div>
  themeClass: string;
  _menuBarConfig = menubarConfig;
  _activeMenuId: string;

  constructor(
    private overlayContainer: OverlayContainer,
    private themePickerService: ThemePickerService,
    private router: Router,
    private titleService: Title  ) {}

  ngOnInit(): void {
    this.titleService.setTitle(environment.appName);
    // subscribe to some source of theme change events, then...
    this.themePickerService.themeChanged$.subscribe((theme) => {
      this.themeClass = theme;
      // remove old theme class and add new theme class
      // we're removing any css class that contains '-theme' string but your theme classes can follow any pattern
      const overlayContainerClasses = this.overlayContainer.getContainerElement().classList;
      const themeClassesToRemove = Array.from(overlayContainerClasses).filter((item: string) => item.includes('-theme'));
      if (themeClassesToRemove.length) {
        overlayContainerClasses.remove(...themeClassesToRemove);
      }
      overlayContainerClasses.add(this.themeClass);
    });

    this.router.events.pipe(filter((e) => e instanceof NavigationEnd))
      .subscribe((e:RouterEvent) => {
        this._menuBarConfig.forEach((config) => {
          if (config.route) {
            const url: string = typeof(config.route) === 'string' ? config.route : config.route[0];
            if (url === e.url) {
              this._activeMenuId = config.id;
            }
          } else {
            // submenu-case
            config.subMenu.forEach((submenu)=> {
              if (submenu.route[0] === e.url) {
                this._activeMenuId = submenu.id;
              }
            })
          }
        })
      })
  }

  handleMenuBarOutput(id: string): void {
    this._activeMenuId = id;
  }
}

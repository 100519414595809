import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import Amplify, { Auth } from 'aws-amplify';
import {awsconfigDev} from "../aws-config-dev";
import {awsconfigProd} from "../aws-config";


if (environment.production) {
  enableProdMode();
  Amplify.configure(awsconfigProd);
  Auth.configure(awsconfigProd);
} else {
  Amplify.configure(awsconfigDev);
  Auth.configure(awsconfigDev);
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {Router} from "@angular/router";

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent implements OnInit {
  searchBarCtrl: FormControl = new FormControl();
  options: string[] = ['One', 'Two', 'Three'];
  formGroup: FormGroup;
  constructor(private fb: FormBuilder, private router: Router) { }

  ngOnInit(): void {
    this.formGroup = this.fb.group({
      searchFilter: null,
      keyword: '',
    })
  }

  searchKeyword(e): void {
    const keyword = e.target.value;
    if (keyword === '') {
      return;
    }
    this.router.navigate(['/app/search', { keyword: keyword, filter: this.formGroup.getRawValue()['searchFilter'] }])
  }

}

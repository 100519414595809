
<div class="app-component" [class]="themeClass" fxLayout="column" fxFlex>
  <app-nav-bar></app-nav-bar>
  <app-menu-bar [menubarModel]="_menuBarConfig" (menuBarOutput)="handleMenuBarOutput($event)" [activeMenuId]="_activeMenuId"></app-menu-bar>
  <app-progress></app-progress>
  <div class="app-body" fxFlex>
    <router-outlet></router-outlet>
  </div>
</div>

<ngx-spinner type = "ball-pulse" color="#f5801b"><p style="color: white" > Loading... </p></ngx-spinner>

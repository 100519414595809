import { Component, OnInit } from '@angular/core';
import {ThemePickerService} from "./theme-picker.service";

@Component({
  selector: 'app-theme-picker',
  templateUrl: './theme-picker.component.html',
  styleUrls: ['./theme-picker.component.scss']
})
export class ThemePickerComponent implements OnInit {

  constructor(private themePickerService: ThemePickerService) { }

  ngOnInit(): void {
  }

  changeTheme(theme: string): void {
    this.themePickerService.themeChanged$.next(theme);
  }
}

import {
  AfterViewInit,
  Component,
  ElementRef, EventEmitter, Input, OnChanges,
  OnInit, Output,
  QueryList,
  Renderer2, SimpleChange, SimpleChanges,
  TemplateRef,
  ViewChildren
} from '@angular/core';
import {Template} from "@angular/compiler/src/render3/r3_ast";
import {MenuBarModel, SubMenuModel} from "./menu-bar.model";

@Component({
  selector: 'app-menu-bar',
  templateUrl: './menu-bar.component.html',
  styleUrls: ['./menu-bar.component.scss']
})
export class MenuBarComponent implements OnInit, OnChanges {
  @Input() menubarModel: MenuBarModel[];
  @Input() activeMenuId: string;

  // _isSubMenuActive: boolean;
  _currParentMenu: string;
  @Output() menuBarOutput: EventEmitter<string> = new EventEmitter<string>();
  @ViewChildren(TemplateRef, {read: ElementRef}) buttons: QueryList<ElementRef>;
  constructor(private renderer: Renderer2) { }

  ngOnInit(): void {
  }

  // handle menu button click
  handleButtonClick(id: string, route: string): void {
    this.menuBarOutput.emit(id);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['activeMenuId']) {
      let atLeastOneSubMenuActive: boolean = false;
      this.menubarModel.forEach((model: MenuBarModel) => {
        if (model.subMenu){
          if (this.isSubMenuActive(model)) {
            this._currParentMenu = model.id;
            atLeastOneSubMenuActive = true;
          }
        }
      })
      if (!atLeastOneSubMenuActive) {
        this._currParentMenu = null;
      }
    }
  }

  private isSubMenuActive(model: MenuBarModel): boolean {
    return model.subMenu.some((m: SubMenuModel) => {
      return m.id === this.activeMenuId;
    });
  }


}

import {CognitoUserInterface} from "@aws-amplify/ui-components";

export enum EurykaUserRole {
  sourceAdmin='sourceAdmin',
  glossaryAdmin='glossaryAdmin',
  consumerAdmin='consumerAdmin',
  dataQualityRuleAdmin='dataQualityRuleAdmin',
  superAdmin='superAdmin',
  tableColumnAdmin='tableColumnAdmin',
  readOnlyUser='readOnlyUser'
}

export interface EurykaUserInterface extends CognitoUserInterface{
  roles: {
    label: string;
    value: EurykaUserRole;
  }[];
}

import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {AppHttpRequestInterceptor} from "./app-services/app-http-interceptor.service";
import {HTTP_INTERCEPTORS} from "@angular/common/http";

@NgModule({
  imports: [
    CommonModule
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: AppHttpRequestInterceptor, multi: true}
  ]
})
export class SharedServicesModule{}

<div class="progress-job-component" fxLayout="row" fxFlex="1 1" fxLayoutGap="16px">
  <mat-card fxLayout="row" fxFlex="1 1" fxLayoutGap="8px" class="mat-elevation-z4" fxLayoutAlign="stretch center">
    <span fxFlex="1 0 75%" fxLayout="column">
      <span>{{jobDescription}}</span>
      <span class="side-note">{{sideNote}}</span>
    </span>
    <mat-progress-spinner
      class="example-margin"
      [mode]="'determinate'"
      [value]="currentProgress" [diameter]="20">
    </mat-progress-spinner>
    <mat-icon (click)="handleClose()" class="close-icon">close</mat-icon>
  </mat-card>
</div>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavBarComponent } from './nav-bar.component';
import {SharedModule} from "../../../shared/shared.module";
import {SearchBarModule} from "../../modular-components/search-bar/search-bar.module";
import {ThemePickerModule} from "../../modular-components/theme-picker/theme-picker.module";
import {AmplifyUIAngularModule} from "@aws-amplify/ui-angular";
import {NavBarService} from "./nav-bar.service";



@NgModule({
  declarations: [NavBarComponent],
  exports: [
    NavBarComponent
  ],
    imports: [
        CommonModule,
        SharedModule.forChild(),
        SearchBarModule,
        ThemePickerModule,
        AmplifyUIAngularModule,
    ],
  providers: [NavBarService]
})
export class NavBarModule { }

<mat-toolbar color="primary" class="nav-bar-header mat-elevation-z10" fxLayout="row" fxLayoutAlign="stretch center">
  <a href="/app/home" class="link" fxFlex="25">
    <button mat-button class="home-button">EURYKA</button>
  </a>
  <div class="search-bar flex-fill">
    <app-search-bar *ngIf="!hideSearchbar"></app-search-bar>
  </div>
  <div fxFlex="25" fxLayoutAlign="end center">
<!--    <app-theme-picker></app-theme-picker>-->
<!--    <button mat-icon-button>-->
<!--      <mat-icon>menu</mat-icon>-->
<!--    </button>-->
<!--    <button mat-icon-button>-->
<!--      <mat-icon>person</mat-icon>-->
<!--    </button>-->
<!--    <button mat-icon-button>-->
<!--      <mat-icon>help_outline</mat-icon>-->
<!--    </button>-->
<!--    <div *ngIf="(authState$ | async) === 'signedin'"><button mat-icon-button *matMenuTriggerFor="menu"><mat-icon>person</mat-icon></button></div>-->
    <button mat-icon-button [matMenuTriggerFor]="menu" *ngIf="user"><mat-icon>account_box</mat-icon></button>
    <button mat-button (click)="handleLogin()" *ngIf="(authState$ | async) !== 'signedin'">Login</button>
    <button mat-button (click)="handleLogout()" *ngIf="(authState$ | async) === 'signedin'">Logout</button>

    <mat-menu #menu="matMenu">
      <button mat-menu-item>
        <mat-icon class="username-icon">person</mat-icon>
        <span>{{user?.username}}</span>
      </button>
<!--      <button mat-menu-item *ngIf="user?.roles?.label">-->
<!--        <mat-icon class="username-icon">admin_panel_settings</mat-icon>-->
<!--        <span>{{user?.roles?.label}}</span>-->
<!--      </button>-->
      <button mat-menu-item *ngFor="let role of user?.roles">
        <mat-icon class="username-icon">admin_panel_settings</mat-icon>
        <span>{{role?.label}}</span>
      </button>
<!--      <button mat-menu-item disabled>-->
<!--        <mat-icon>voicemail</mat-icon>-->
<!--        <span>Check voice mail</span>-->
<!--      </button>-->
<!--      <button mat-menu-item>-->
<!--        <mat-icon>notifications_off</mat-icon>-->
<!--        <span>Disable alerts</span>-->
<!--      </button>-->
    </mat-menu>
  </div>

  <ng-template #authTmpl>
<!--    <amplify-loading-spinner *ngIf="loading"></amplify-loading-spinner>-->
    <mat-spinner *ngIf="loading"></mat-spinner>
    <div class="authTmpl" *ngIf="!loading">
      <amplify-authenticator>
        <amplify-sign-in slot="sign-in" [hideSignUp]="true"></amplify-sign-in>
      </amplify-authenticator>
    </div>
  </ng-template>

</mat-toolbar>

<div class="search-bar-component" fxLayout="row" [formGroup]="formGroup">
<!--  <button mat-button [matMenuTriggerFor]="searchCriteria" aria-label="Select a search criteria"-->
<!--          matTooltip="Select a search criteria" class="filter-button">-->
<!--    <span>ALL</span>-->
<!--    <mat-icon>arrow_drop_down</mat-icon>-->
<!--  </button>-->
  <div class="search-bar" fxFlex="1 1">
<!--    <mat-form-field>-->
    <mat-icon class="search-icon">search</mat-icon>
    <input class="search-field" (keydown.enter)="searchKeyword($event)">
<!--    </mat-form-field>-->
  </div>

<!--  <mat-autocomplete #auto="matAutocomplete">-->
<!--    <mat-option *ngFor="let option of options" [value]="option">{{option}}</mat-option>-->
<!--  </mat-autocomplete>-->
</div>
<mat-menu #searchCriteria="matMenu">
  <button mat-menu-item>Delivery Channel
  </button>
  <button mat-menu-item>Consumer Feed
  </button>
  <button mat-menu-item>Consumer Contract
  </button>
  <button mat-menu-item>Source Feed
  </button>
  <button mat-menu-item>Data Quality
  </button>
  <button mat-menu-item>Report
  </button>
</mat-menu>

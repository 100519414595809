import {Inject, Injectable} from "@angular/core";
import {BehaviorSubject, Observable, Subject} from "rxjs";
import * as uuid from "uuid";

export interface ProgressModel {
  id: string;
  jobDescription: string;
  sideNote: string;
  progress: number;
}

@Injectable({providedIn: "root"})
export class AppProgressService {

  private _progress$: BehaviorSubject<ProgressModel[]> = new BehaviorSubject<ProgressModel[]>([]);
  get progress$(): Observable<ProgressModel[]> {
    return this._progress$.asObservable();
  }

  constructor() {
  }

  registerJob(jobDescription: string, sideNote?: string): string {
    const id = uuid.v4();
    const currentProgress: ProgressModel[] = this._progress$.getValue();
    currentProgress.push({id, jobDescription, progress: 0, sideNote});
    this._progress$.next(currentProgress);
    return id;
  }

  updateJob(id: string, progress: number, jobDescription?: string, sideNote?: string): void {
    const originalModel: ProgressModel[] = this._progress$.getValue();
    const job: ProgressModel = originalModel.find((progress) => progress.id === id);
    if (job) {
      job.jobDescription = jobDescription ? jobDescription : job.jobDescription;
      job.progress = progress;
      // job.sideNote = sideNote ? sideNote :;
      this._progress$.next(originalModel);
    }
  }

  deleteJob(id: string): boolean {
    const jobIdx: number = this._progress$.getValue().findIndex((progress) => progress.id === id);
    if (jobIdx >= 0) {
      const currentProgress: ProgressModel[] = this._progress$.getValue();
      currentProgress.splice(jobIdx)
      this._progress$.next(currentProgress);
    }
    return false;
  }
}
